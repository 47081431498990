#milesrank-more-stats {
  margin-top: 1em;
}
#milesrank-more-stats .ui.statistic>.value, 
#milesrank-more-stats .ui.statistics .statistic>.value {
  font-size: 2rem!important;
}

.milesrank-stats.dimmed {
  padding: 2em 0;
}