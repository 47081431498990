#milesrank-list-ranking .ui.selection.list>.item:hover {
  background-color: #fea90088;
}
#milesrank-list-ranking .ui.selection.list>.item:active {
  background-color: #fea900aa;
}

#milesrank-list-ranking .ui.list .list>.item.ranking-item>.position, 
#milesrank-list-ranking .ui.list>.item.ranking-item>.position {
  display: table-cell;
  background-color: transparent;
  margin: 0;
  vertical-align: top;
  padding-right: .5em;
}

#milesrank-list-ranking .ui.floating.label.rank-change-up,
#milesrank-list-ranking .ui.floating.label.rank-change-down,
#milesrank-list-ranking .ui.floating.label.rank-debut {
  top: -.4em;
  left: -.2em;
  cursor: default;
}

#milesrank-list-ranking .ui.floating.label.rank-debut {
  left: -.9em;
}