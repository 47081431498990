h1.ui.header {
  color: #fff;
}

#root .ui.segment {
  background-color: #fffd;
}

#milesrank-main-menu.ui.menu .dropdown.item .menu {
  background-color: #3d3e3f;
  
}

.gdpr-filler i.icon.gdpr-icon {
  margin-right: 0
}
