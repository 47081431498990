#milesrank-year-select {
  min-width: auto;
}

#milesrank-year-select.ui.dropdown {
  background: #fffa;
}

#milesrank-year-select.ui.dropdown .menu>.item {
  font-size: 2rem;
  padding: .78571429em 2.1em .78571429em 1em!important;
}